import React from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Header from "../components/header"
import Hero from "../components/hero"
import SEO from "../components/seo"
import GenerateButton from "../components/generate-button"
import { useTranslation, I18nextContext } from 'gatsby-plugin-react-i18next';

function IndexPage() {
  const { t } = useTranslation()
  const context = React.useContext(I18nextContext)

  console.log(context)

  return (
    <Layout>
      <SEO title={t("front-page-hero-title")} description={t("front-page-hero-description")} lang={context.language} />
      <div className="hero-wrapper">
        <StaticImage src="../images/bg/06.jpg" alt="" className="hero-wrapper__background" />
        <Header/>
        <main className="main" id="content">
          <Hero>
            <h1 className="hero__title">{t("front-page-hero-title")}</h1>
            <p className="hero__description">{t("front-page-hero-description")}</p>
            <GenerateButton>
              {t("generate-btn-caption")}
            </GenerateButton>
          </Hero>
        </main>
      </div>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;